import { addDays, getDate, getDay, getUnixTime, isBefore } from "date-fns";
import { IInitilaDate } from "src/store/slices/date";
import { Product } from "src/store/slices/products";

export const GLOBAL_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost/lowiskozgoda"
    : "https://systemrezerwacji.palmax.com.pl";

export const months = [
  "Sty",
  "Lut",
  "Mar",
  "Kwi",
  "Maj",
  "Cze",
  "Lip",
  "Sie",
  "Wrz",
  "Paź",
  "Lis",
  "Gru",
];

export const wholeMonths = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

export const daysOfWeek = ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"];

interface IGetDays {
  range: { from: number; to: number };
  date: { year: number; month: number };
}

export const getOrderUrl = (id: string) => {
  return `${GLOBAL_URL}/wp-admin/post.php?post=${id}&action=edit`;
};

export const getDays = ({ range, date }: IGetDays) => {
  const output = [];
  const daysLength = range.to;
  const { year, month } = date;
  for (let i = 1; i <= daysLength; i++) {
    output.push({
      number: i,
      label: daysOfWeek[getDay(new Date(year, month, i))],
      active: false,
    });
  }
  return output;
};

export const getCells = (
  range: { from: number; to: number },
  currentDate: IInitilaDate,
  displayedDate: IInitilaDate
) => {
  const output = [];
  const cellsLength = range.to;
  for (let i = 1; i <= cellsLength; i++) {
    const { year, month, day } = currentDate;
    const isOutOfRange = isBefore(
      new Date(displayedDate.year, displayedDate.month, i),
      new Date(year, month, day)
    );
    output.push({ isSelected: false, isOutOfRange });
  }
  return output;
};

export const parseProductsToCells = ({
  cellsArray,
  productProps,
  rangeTimestamp,
}: any) => {
  const cellsRange = [...cellsArray] as any;
  for (let i = 0; i < productProps.events.length; i++) {
    const { from, to, isAdmin } = productProps.events[i];
    const fromRangeTimestamp = rangeTimestamp.from;
    const toRangeTimestamp = rangeTimestamp.to;
    const startDate = new Date(from);
    const endDate = addDays(new Date(to), 1);
    const fromTimestamp = getUnixTime(startDate);
    const toTimestamp = getUnixTime(endDate);
    const startDay = getDate(startDate);
    const endDay = getDate(endDate);
    if (fromRangeTimestamp < fromTimestamp && toRangeTimestamp > toTimestamp) {
      const cellIndex = startDay - 1;
      const length = cellIndex + (endDay - startDay);

      for (let i = cellIndex; i <= length; i++) {
        let isFirst = false;
        let isLast = false;
        if (i === cellIndex) isFirst = true;
        if (i === length) isLast = true;
        cellsRange[i] = { isFirst, isLast, isAdmin, disabled: true };
      }
    } else if (
      fromRangeTimestamp > fromTimestamp &&
      toRangeTimestamp < toTimestamp
    ) {
      for (let i = 0; i < 32; i++) {
        if (cellsRange[i]) cellsRange[i] = { isAdmin, disabled: true };
      }
    } else if (
      fromTimestamp < toRangeTimestamp &&
      toTimestamp > toRangeTimestamp
    ) {
      const cellIndex = startDay - 1;
      for (let i = cellIndex; i < 32; i++) {
        let isFirst = false;
        if (i === cellIndex) isFirst = true;
        if (cellsRange[i]) cellsRange[i] = { isFirst, isAdmin, disabled: true };
      }
    } else if (
      fromTimestamp < fromRangeTimestamp &&
      toTimestamp > fromRangeTimestamp
    ) {
      for (let i = 0; i < endDay; i++) {
        let isLast = false;
        if (i === endDay - 1) isLast = true;
        if (cellsRange[i]) cellsRange[i] = { isLast, isAdmin, disabled: true };
      }
    }
  }
  return cellsRange as
    | {
        isAdmin?: boolean;
        isFirst?: boolean;
        isLast?: boolean;
        disabled?: boolean;
        isSelected?: boolean;
      }[]
    | null[];
};

export const parseProduct = ({
  products,
  range,
  timestamp,
  currentDate,
  displayedDate,
}: {
  products: Product[];
  range: { from: number; to: number };
  timestamp: { from: number; to: number };
  currentDate: IInitilaDate;
  displayedDate: IInitilaDate;
}) => {
  const productLabels = [];
  const rows = [];
  const cells = getCells(range, currentDate, displayedDate) as any[];
  for (let l = 0; l < products.length; l++) {
    const cellsCopy = [...cells];
    const { id, name, slug, events } = products[l];
    productLabels.push({ id, name, slug });
    for (let j = 0; j < events.length; j++) {
      const { id: eventId, from, to, isAdmin, post_id, owner } = events[j];
      const fromRangeTimestamp = timestamp.from;
      const toRangeTimestamp = timestamp.to;
      const startDate = new Date(from);
      const endDate = addDays(new Date(to), 1);
      const fromTimestamp = getUnixTime(startDate);
      const toTimestamp = getUnixTime(endDate);
      const startDay = getDate(startDate);
      const endDay = getDate(endDate);
      const eventBase = {
        id: eventId,
        postId: post_id,
        isAdmin,
        owner,
        disabled: true,
      };
      if (
        fromRangeTimestamp < fromTimestamp &&
        toRangeTimestamp > toTimestamp
      ) {
        const cellIndex = startDay - 1;
        const length = cellIndex + (endDay - startDay);

        for (let i = cellIndex; i <= length; i++) {
          let place = false;
          let {
            postId = false,
            isAdmin = false,
            isFirst = false,
            isLast = false,
            isOutOfRange = false,
          } = cellsCopy[i];
          if (isFirst && postId) {
            isFirst = false;
            isLast = true;
            place = true;
          }
          if (i === cellIndex) isFirst = true;
          if (i === length) isLast = true;
          cellsCopy[i] = {
            ...eventBase,
            place,
            prevPostId: postId,
            prevIsAdmin: isAdmin,
            isOutOfRange,
            isFirst,
            isLast,
          };
        }
      } else if (
        fromRangeTimestamp > fromTimestamp &&
        toRangeTimestamp < toTimestamp
      ) {
        for (let i = 0; i < 32; i++) {
          if (cellsCopy[i]) cellsCopy[i] = eventBase;
        }
      } else if (
        fromTimestamp < toRangeTimestamp &&
        toTimestamp > toRangeTimestamp
      ) {
        const cellIndex = startDay - 1;
        for (let i = cellIndex; i < 32; i++) {
          let isFirst = false;
          let isLast = false;
          let isOutOfRange = false;
          let postId = false;
          let prevIsAdmin = false;
          if (cellsCopy[i]) {
            postId = cellsCopy[i].postId || false;
            prevIsAdmin = cellsCopy[i].isAdmin || false;
            isFirst = cellsCopy[i].isFirst || false;
            isLast = cellsCopy[i].isLast || false;
            isOutOfRange = cellsCopy[i].isOutOfRange || false;
          }
          if (i === cellIndex) isFirst = true;
          if (cellsCopy[i])
            cellsCopy[i] = {
              ...eventBase,
              prevPostId: postId,
              prevIsAdmin,
              isFirst,
              isLast,
              isOutOfRange,
            };
        }
      } else if (
        fromTimestamp < fromRangeTimestamp &&
        toTimestamp > fromRangeTimestamp
      ) {
        for (let i = 0; i < endDay; i++) {
          let postId = false;
          let isLast = false;
          let isOutOfRange = false;
          let prevIsAdmin = false;
          if (cellsCopy[i]) {
            postId = cellsCopy[i].postId || false;
            prevIsAdmin = cellsCopy[i].isAdmin || false;
            isLast = cellsCopy[i].isLast || false;
            isOutOfRange = cellsCopy[i].isOutOfRange || false;
          }
          if (i === endDay - 1) isLast = true;
          if (cellsCopy[i])
            cellsCopy[i] = {
              ...eventBase,
              prevPostId: postId,
              prevIsAdmin,
              isLast,
              isOutOfRange,
            };
        }
      }
    }
    rows.push(cellsCopy);
  }
  return { labels: productLabels, rows };
};

// export const parseProducts = (products: Product[]) => {
//   console.log(products);
//   const output = {} as {
//     [key: string]: { name: string; events: { from: string; to: string }[] };
//   }[];
//   for (let i = 0; i < products.length; i++) {
//     const { id, name, from, to } = products[i];
//     if (output[id]) output[id].events.push({ from, to });
//     else output[id] = { id, name, events: [{ from, to }] };
//   }
//   return Object.values(output);
// };
