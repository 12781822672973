import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRange } from "src/store/slices/date";
import { RootState } from "src/store/store";
import { getDays } from "src/utils";

const useDaysOfMonth = () => {
  const dispatch = useDispatch();

  const displayedDate = useSelector(({ date }: RootState) => {
    return date.displayed;
  });
  const daysOfMonth = useSelector(({ date }: RootState) => {
    return date.range.days;
  });

  useEffect(() => {
    dispatch(setRange());
  }, [displayedDate]);

  return {
    daysOfMonth: getDays({ range: daysOfMonth, date: displayedDate }),
  };
};

export default useDaysOfMonth;
