import { createSlice } from "@reduxjs/toolkit";
import {
  format,
  getUnixTime,
  getMonth,
  getDate,
  getYear,
  getDaysInMonth,
  startOfMonth,
} from "date-fns";

const currentDate = new Date();

const initialDate = {
  year: getYear(currentDate),
  month: getMonth(currentDate),
  day: getDate(currentDate),
  timestamp: getUnixTime(currentDate),
  iso: format(currentDate, "yyyy-dd-MM"),
};

export type IInitilaDate = typeof initialDate;

export const dateSlice = createSlice({
  name: "date",
  initialState: {
    current: initialDate,
    displayed: initialDate,
    range: {
      timestamp: {
        from: getUnixTime(startOfMonth(currentDate)),
        to: getUnixTime(
          startOfMonth(
            new Date(initialDate.year, initialDate.month + 1, initialDate.day)
          )
        ),
      },
      days: {
        from: 1,
        to: getDaysInMonth(currentDate),
      },
    },
    overflowEvents: {},
    selectedEvents: {},
    addToCartEvents: {},
    products: [],
  },
  reducers: {
    incrementMonth: ({ displayed }) => {
      const { month } = displayed;
      if (month === 11) {
        displayed.month = 0;
        displayed.year++;
      } else {
        displayed.month++;
      }
    },
    decrementMonth: ({ displayed }) => {
      const { month } = displayed;
      if (month === 0) {
        displayed.month = 11;
        displayed.year--;
      } else {
        displayed.month--;
      }
    },
    incrementYear: ({ displayed }) => {
      displayed.year++;
    },
    decrementYear: ({ displayed }) => {
      displayed.year--;
    },
    setCurrentDate: (state) => {
      state.displayed = state.current;
    },
    setMonth: ({ displayed }, { payload }: { payload: number }) => {
      displayed.month = payload;
    },
    setRange: ({ range, displayed }) => {
      const { year, month } = displayed;
      const timestamp = {
        from: getUnixTime(startOfMonth(new Date(year, month))),
        to: getUnixTime(startOfMonth(new Date(year, month + 1))),
      };
      const days = {
        from: 1,
        to: getDaysInMonth(new Date(year, month)),
      };
      range.timestamp = timestamp;
      range.days = days;
    },
    setAddToCartEvents: (
      { addToCartEvents },
      { payload: { key, row, value } }
    ) => {
      if (!addToCartEvents[key]) {
        addToCartEvents[key] = {
          [row]: value,
        };
      } else {
        addToCartEvents[key] = {
          ...addToCartEvents[key],
          [row]: value,
        };
      }
    },
    setProducts: ({ products }, { payload: { productsFromApi } }) => {
      products = productsFromApi;
    },
    setSelectedEvents: (
      { selectedEvents, overflowEvents },
      { payload: { key, value, nextKey } }
    ) => {
      for (let i = 0; i < value.length; i++) {
        const arr = [];
        const row = value[i];
        for (let j = 0; j < row.length; j++) {
          const { isFirstSelected, length } = row[j];
          if (isFirstSelected) {
            arr.push({
              isOverflowing: row[j + length - 1].isOverflowing ? true : false,
            });
          }
        }
        const isOverflowing = !!arr.length
          ? arr[arr.length - 1].isOverflowing
          : false;

        if (isOverflowing) {
          overflowEvents[nextKey] = {
            ...overflowEvents[nextKey],
            [i]: arr[arr.length - 1],
          };
        } else {
          overflowEvents[nextKey] = {
            ...overflowEvents[nextKey],
            [i]: { isOverflowing: false },
          };
        }
      }
      selectedEvents[key] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  incrementMonth,
  decrementMonth,
  incrementYear,
  decrementYear,
  setCurrentDate,
  setMonth,
  setRange,
  setSelectedEvents,
  setProducts,
  setAddToCartEvents,
} = dateSlice.actions;

export default dateSlice.reducer;
