import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import {
  incrementMonth,
  decrementMonth,
  setMonth,
} from "src/store/slices/date";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
import { months } from "src/utils";
import "./NavigationMonthList.scss";

export interface INavigationMonthListProps {}

const NavigationMonthList: React.FC<INavigationMonthListProps> = (props) => {
  const dispatch = useDispatch();
  const activeMonth = useSelector(
    ({ date }: RootState) => date.displayed.month
  );

  return (
    <div className="navigation__wrapper">
      <NavigationArrow type="left" onClick={() => dispatch(decrementMonth())} />
      <ul className="navigation__monthList">
        {months.map((month, index) => (
          <li
            key={index}
            onClick={() => dispatch(setMonth(index))}
            className={`navigation__month ${index === activeMonth && "active"}`}
          >
            {month}
          </li>
        ))}
      </ul>
      <NavigationArrow
        type="right"
        onClick={() => dispatch(incrementMonth())}
      />
    </div>
  );
};

export default NavigationMonthList;
