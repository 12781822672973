import React from "react";
import { useSelector } from "react-redux";
import useDaysOfMonth from "src/hooks/useDaysOfMonth";
import { RootState } from "src/store/store";
import { wholeMonths } from "src/utils";
import "./BodyLabelList.styles.scss";

export interface IBodyLabelListProps {}

const BodyLabelList: React.FC<IBodyLabelListProps> = (props) => {
  let { daysOfMonth } = useDaysOfMonth();

  const { month, year } = useSelector(
    ({ date: { displayed } }: RootState) => displayed
  );

  const {
    month: currentMonth,
    year: currentYear,
    day: currentDay,
  } = useSelector(({ date: { current } }: RootState) => current);
  const isDayInCurrentView = month === currentMonth && year === currentYear;

  const getDaysOfMonth = () => {
    const daysOfMonthCopy = [...daysOfMonth];
    if (isDayInCurrentView && daysOfMonthCopy[currentDay - 1])
      daysOfMonthCopy[currentDay - 1].active = true;
    return daysOfMonthCopy;
  };

  return (
    <div className="body__header">
      <div className="body__labelDate">
        <div>{year}</div>
        <div>{wholeMonths[month]}</div>
      </div>
      <ul className="body__labelList">
        {getDaysOfMonth().map(({ number, label, active }) => (
          <li
            key={number}
            className={`body__singleLabel ${active && "active"}`}
          >
            <span>{number}</span>
            <span>{label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BodyLabelList;
