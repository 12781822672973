import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableDragSelect from "src/lib/Selectable";
import Loader from "src/components/Loader/Loader";
import { Product } from "src/store/slices/products";
import { setSelectedEvents } from "src/store/slices/date";
import { RootState } from "src/store/store";
import { getOrderUrl, parseProduct } from "src/utils";
import "./BodyProductList.styles.scss";

export interface IBodyProductListProps {
  products: Product[];
  isLoading: boolean;
}

let filterTimeout: any;

const BodyProductList: React.FC<IBodyProductListProps> = ({
  products,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const rangeDays = useSelector(({ date }: RootState) => date.range.days);
  const rangeTimestamp = useSelector(
    ({ date }: RootState) => date.range.timestamp
  );
  const selectedEvents = useSelector(
    ({ date }: RootState) => date.selectedEvents
  );
  const overflowedEvents = useSelector(
    ({ date }: RootState) => date.overflowEvents
  );
  const currentDate = useSelector(({ date }: RootState) => date.current);
  const displayedDate = useSelector(({ date }: RootState) => date.displayed);

  const parsedProducts = useMemo(
    () =>
      parseProduct({
        products,
        range: rangeDays,
        timestamp: rangeTimestamp,
        currentDate,
        displayedDate,
      }),
    [products]
  );

  const [currentCells, setCurrentCells] = useState(parsedProducts.rows);
  const [highlightedCell, setHighlightedCell] = useState<string>("");
  const [onLeave, setOnLeave] = useState(false);

  useEffect(() => {
    if (!parsedProducts.rows.length) return;
    const cells = [...parsedProducts.rows];
    const currentOverflowedEvents = overflowedEvents[rangeTimestamp.from];
    for (const key in currentOverflowedEvents) {
      const { isOverflowing } = currentOverflowedEvents[key];
      cells[key][0] = {
        ...cells[key][0],
        isSelected: false,
        isStartingMonth: isOverflowing,
      };
    }
    setCurrentCells(cells);
  }, [parsedProducts.rows]);

  useEffect(() => {
    const currentOverflowedEvents = overflowedEvents[rangeTimestamp.from];
    if (currentOverflowedEvents && !!currentCells.length) {
      const cells = JSON.parse(JSON.stringify(currentCells));
      for (const key in currentOverflowedEvents) {
        const { isOverflowing } = currentOverflowedEvents[key];
        cells[key][0] = {
          ...cells[key][0],
          isSelected: false,
          isStartingMonth: isOverflowing,
        };
      }
      setCurrentCells(cells);
    }
  }, [overflowedEvents]);

  useEffect(() => {
    const timestampSelectedEvents = selectedEvents[rangeTimestamp.from];
    if (timestampSelectedEvents) {
      setCurrentCells(timestampSelectedEvents);
    }
    return () => {
      const fromTimestampKey = rangeTimestamp.from;
      const toTimestampKey = rangeTimestamp.to;
      setCurrentCells((prevCells) => {
        dispatch(
          setSelectedEvents({
            key: fromTimestampKey,
            value: prevCells,
            nextKey: toTimestampKey,
          })
        );
        return parsedProducts.rows;
      });
    };
  }, [rangeTimestamp]);

  function handleChange(cells: any) {
    setCurrentCells(cells);
  }

  const highlightCell = (id: string) => {
    setHighlightedCell(id);
    setOnLeave(false);
  };

  function deHightlight() {
    setOnLeave(true);
    clearTimeout(filterTimeout);

    filterTimeout = setTimeout(() => {
      setOnLeave((prevState) => {
        if (prevState) setHighlightedCell("");
        return prevState;
      });
    }, 100);
  }

  return (
    <div className="body-products">
      <Loader isLoading={isLoading} />
      <div className="body-products__labelList">
        {parsedProducts.labels.map(({ id, name, slug }) => (
          <a
            key={id}
            href={slug}
            className="body-products__itemContent"
            title={name}
          >
            <span>{name}</span>
          </a>
        ))}
      </div>
      {!!currentCells.length && (
        <TableDragSelect
          value={currentCells}
          onChange={handleChange}
          products={parsedProducts.labels}
        >
          {parsedProducts.rows.map((cells, index) => {
            return (
              <tr key={index} className="body-products__item">
                {cells.map((event, cIndex) => (
                  <td
                    key={
                      event
                        ? `${event.isOutOfRange},${event.isFirst},${
                            event.isLast
                          },${event.id},${highlightedCell === event.postId},${
                            highlightedCell === event.prevPostId
                          }`
                        : `${event.isOutOfRange},${cIndex}`
                    }
                    className={`body-products__cell ${
                      event && event.isOutOfRange ? "out-of-range" : ""
                    }`}
                    //@ts-ignore
                    disabled={
                      (event.disabled && !event.isFirst && !event.isLast) ||
                      (event.isFirst && event.isLast) ||
                      event.isOutOfRange
                        ? true
                        : false
                    }
                  >
                    {event.isOutOfRange && (
                      <div className="body-products__out-of-range"></div>
                    )}
                    {event.disabled &&
                      (event.owner ? (
                        <>
                          <a
                            href={getOrderUrl(event.postId)}
                            className={`body-products__event ${
                              event.isAdmin && "isAdmin"
                            } ${
                              highlightedCell === event.postId
                                ? "highlight"
                                : ""
                            }`}
                            style={{
                              width: `${
                                event.isFirst || event.isLast ? 50 : 100
                              }%`,
                              marginLeft: `${event.isFirst ? 50 : 0}%`,
                            }}
                            onMouseEnter={() => highlightCell(event.postId)}
                            onMouseOut={deHightlight}
                          >
                            <span></span>
                          </a>
                          {event.prevPostId && (
                            <a
                              onMouseEnter={() =>
                                highlightCell(event.prevPostId)
                              }
                              onMouseOut={deHightlight}
                              href={getOrderUrl(event.prevPostId)}
                              className={`body-products__event ${
                                event.prevIsAdmin && "isAdmin"
                              } ${
                                highlightedCell === event.prevPostId
                                  ? "highlight"
                                  : ""
                              }`}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: event.place ? "50%" : 0,
                                width: "50%",
                                borderRight: !event.place
                                  ? "1px solid #e0e0e0"
                                  : "none",
                                borderLeft: event.place
                                  ? "1px solid #e0e0e0"
                                  : "none",
                              }}
                            >
                              <span></span>
                            </a>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            className={`body-products__event`}
                            style={{
                              width: `${
                                event.isFirst && event.isLast
                                  ? 100
                                  : event.isFirst || event.isLast
                                  ? 50
                                  : 100
                              }%`,
                              marginLeft: `${
                                event.isFirst && event.isLast
                                  ? 0
                                  : event.isFirst
                                  ? 50
                                  : 0
                              }%`,
                            }}
                          ></div>
                          {event.prevPostId && (
                            <div
                              className={`body-products__event`}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: event.place ? "50%" : 0,
                                width: "50%",
                                borderRight: !event.place
                                  ? "1px solid #e0e0e0"
                                  : "none",
                                borderLeft: event.place
                                  ? "1px solid #e0e0e0"
                                  : "none",
                              }}
                            ></div>
                          )}
                        </>
                      ))}
                  </td>
                ))}
              </tr>
            );
          })}
        </TableDragSelect>
      )}
    </div>
  );
};

export default BodyProductList;
