import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import Body from "src/components/Body/Body";
import Footer from "src/components/Footer/Footer";
import Navigation from "src/components/Navigation/Navigation";
import { useGetProductsWithBooksQuery } from "./store/slices/products";
import "src/styles/App.css";

function App() {
  const rangeTimestamp = useSelector(
    ({ date }: RootState) => date.range.timestamp
  );
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetProductsWithBooksQuery(rangeTimestamp);

  return (
    <div className="global-container">
      <div className="global-wrapper">
        <Navigation isFetching={isFetching} />
        <Body products={data} isLoading={isLoading || isFetching} />
        <Footer />
      </div>
    </div>
  );
}

export default App;
