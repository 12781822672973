import React from "react";
import { useDispatch } from "react-redux";
import NavigationMonthList from "../NavigationMonthList/NavigationMonthList";
import NavigationYearChanger from "../NavigationYearChanger/NavigationYearChanger";
import { setCurrentDate } from "src/store/slices/date";
import "./Navigation.styles.scss";

export interface INavigationProps {
  isFetching: boolean;
}

const Navigation: React.FC<INavigationProps> = ({ isFetching }) => {
  const dispatch = useDispatch();

  return (
    <nav className={`navigation__container ${isFetching && "isFetching"}`}>
      <div className="btn active" onClick={() => dispatch(setCurrentDate())}>
        Dzisiaj
      </div>
      <NavigationMonthList />
      <NavigationYearChanger />
    </nav>
  );
};

export default Navigation;
