import React from "react";
import "./NavigationArrow.styles.scss";

export interface INavigationArrowProps {
  onClick?: () => void;
  type?: "left" | "right" | "up" | "down";
}

const NavigationArrow: React.FC<INavigationArrowProps> = ({
  type = "down",
  onClick = () => {},
  ...props
}) => {
  return (
    <div className={`navigation-arrow ${type}`} onClick={onClick}>
      <svg
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        {...props}
      >
        <path
          d="M6.09 6.9675L9 9.8775L11.91 6.9675C11.9794 6.89806 12.0619 6.84299 12.1526 6.80541C12.2433 6.76783 12.3405 6.74849 12.4387 6.74849C12.5369 6.74849 12.6342 6.76783 12.7249 6.80541C12.8156 6.84299 12.8981 6.89806 12.9675 6.9675C13.0369 7.03694 13.092 7.11937 13.1296 7.21009C13.1672 7.30082 13.1865 7.39805 13.1865 7.49625C13.1865 7.59445 13.1672 7.69169 13.1296 7.78241C13.092 7.87313 13.0369 7.95556 12.9675 8.025L9.525 11.4675C9.45561 11.537 9.3732 11.5922 9.28247 11.6298C9.19174 11.6675 9.09447 11.6868 8.99625 11.6868C8.89802 11.6868 8.80076 11.6675 8.71003 11.6298C8.6193 11.5922 8.53688 11.537 8.4675 11.4675L5.025 8.025C4.95547 7.95562 4.90031 7.8732 4.86267 7.78247C4.82504 7.69174 4.80566 7.59448 4.80566 7.49625C4.80566 7.39802 4.82504 7.30076 4.86267 7.21003C4.90031 7.1193 4.95547 7.03689 5.025 6.9675C5.3175 6.6825 5.7975 6.675 6.09 6.9675V6.9675Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default NavigationArrow;
