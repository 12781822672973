import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrementYear, incrementYear } from "src/store/slices/date";
import { RootState } from "src/store/store";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
import "./NavigationYearChanger.styles.scss";

export interface INavigationYearChangerProps {}

const NavigationYearChanger: React.FC<INavigationYearChangerProps> = (
  props
) => {
  const dispatch = useDispatch();
  const displayedYear = useSelector(
    ({ date }: RootState) => date.displayed.year
  );

  return (
    <div className="navigation__yearChanger">
      <NavigationArrow type="left" onClick={() => dispatch(decrementYear())} />
      <div className="navigation__year active">{displayedYear}</div>
      <NavigationArrow type="right" onClick={() => dispatch(incrementYear())} />
    </div>
  );
};

export default NavigationYearChanger;
