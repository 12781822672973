import React from "react";
import { Product } from "src/store/slices/products";
import BodyLabelList from "../BodyLabelList/BodyLabelList";
import BodyProductList from "../BodyProductList/BodyProductList";
import "./Body.styles.scss";

export interface IBodyProps {
  products: Product[];
  isLoading: boolean;
}

const Body: React.FC<IBodyProps> = ({ products, isLoading }) => {
  return (
    <div className="body__container">
      <BodyLabelList />
      <BodyProductList products={products} isLoading={isLoading} />
    </div>
  );
};

export default Body;
