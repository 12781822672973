import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import pl from "date-fns/locale/pl";
import { RootState } from "src/store/store";
import { addDays } from "date-fns/esm";
import axios from "axios";
import { GLOBAL_URL } from "src/utils";

export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = (props) => {
  const [mainEvents, setEvents] = useState<any>([]);
  const [queryUrl, setQueryUrl] = useState<any>("");
  const [isLoading, setLoading] = useState<any>(false);

  const addToCartEvents = useSelector(
    ({ date }: RootState) => date.addToCartEvents
  );

  useEffect(() => {
    const { events, query } = parseEvents();
    setEvents(events);
    setQueryUrl(query);
  }, [addToCartEvents]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "add_to_cart_events");
    formData.append("booking", "true");
    formData.set("products", queryUrl);
    try {
      await axios.post(`${GLOBAL_URL}/wp-admin/admin-ajax.php`, formData);
      window.location.href = `${GLOBAL_URL}/podsumowanie-rezerwacji/`;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const parseEvents = () => {
    const parsedEvents = {};
    const array = Object.values(addToCartEvents);
    for (let i = 0; i < array.length; i++) {
      const eventsObj = array[i] as any;
      for (const key in eventsObj) {
        const events = eventsObj[key];
        if (parsedEvents[key]) {
          parsedEvents[key] = {
            ...parsedEvents[key],
            events: [...parsedEvents[key].events, ...events],
          };
        } else {
          if (events[0]) {
            const { product } = events[0];
            parsedEvents[key] = {
              ...product,
              events,
            };
          }
        }
      }
    }
    const eventsValues = Object.values(parsedEvents) as any[];
    const eventsValuesCount = eventsValues.length;
    let query = "";
    for (let j = 0; j < eventsValuesCount; j++) {
      const { id, events } = eventsValues[j];
      const eventsCount = events.length;
      query += `${id}:`;
      for (let n = 0; n < eventsCount; n++) {
        const { year, month, startDay, endDay } = events[n];
        const sDay = startDay < 10 ? `0${startDay}` : startDay;
        const eDay = endDay < 10 ? `0${endDay}` : endDay;
        const sMonth = month < 10 ? `0${month}` : month;
        query += `${year}-${sMonth}-${sDay}_${year}-${sMonth}-${eDay}`;
        if (eventsCount - 1 !== n) query += ",";
      }
      if (eventsValuesCount - 1 !== j) query += "|";
    }
    return { events: eventsValues, query };
  };

  const showSummary = !!mainEvents.length;

  return showSummary ? (
    <div className="calendar-summary">
      <h3>Rezerwacje</h3>
      {mainEvents.map(({ id, name, events }: any) => (
        <div key={id}>
          <b>{name}: </b>
          {events.map(
            ({ year, month, startDay, endDay }: any, index: number) => (
              <span key={index}>
                {format(new Date(year, month - 1, startDay), "d MMMM yyyy", {
                  locale: pl,
                })}{" "}
                12:00 do{" "}
                {format(
                  addDays(new Date(year, month - 1, endDay), 1),
                  "d MMMM yyyy",
                  {
                    locale: pl,
                  }
                )}{" "}
                12:00
                {index !== events.length - 1 ? " | " : ""}
              </span>
            )
          )}
        </div>
      ))}
      <button
        onClick={handleSubmit}
        className="submit btn active"
        disabled={isLoading ? true : false}
      >
        Przejdź do podsumowania
      </button>
    </div>
  ) : null;
};

export default Footer;
